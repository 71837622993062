<template>
  <div class="bottom-buttons">
    <v-bottom-navigation grow height="48">
      <slot></slot>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: 'GBottomButtons',
  props: {
    // 24.07.12 팝업의 경우 position: absolute로 고정시 X축 스크롤 생겨서 구분하여 적용
    popupFlag: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
/* 빌드 시 props true값이 부분적으로 미적용되는 현상으로 인해 수정 */
.bottom-buttons {height:48px !important;position : relative !important;bottom:0 !important;width:calc(100% - 8px) !important;}
.bottom-buttons >>> .v-bottom-navigation {padding:0;height:48px;box-shadow: none;}
.bottom-buttons >>> .v-bottom-navigation .v-btn:not(.v-btn--active) {color:#fff !important;}
.bottom-buttons >>> .v-bottom-navigation .v-btn {width:100%;margin:0;line-height:48px !important;font-family: Pretendard;font-size: 16px;font-weight: 600;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;text-align: left;color: #fff !important;max-width: none !important;}
.bottom-buttons >>> .v-item-group.v-bottom-navigation .v-btn .v-btn__content {flex-direction: inherit;}
</style>
