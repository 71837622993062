import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './vuex/store'
// import store from './vuexTest/store'
import { i18n } from './i18n'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import VueCookies from 'vue-cookies'
import MyPlugin from './plugins/myPlugin.js'
import Vuebar from 'vuebar' // scrollbar design
import '@/assets/css/common.css' // common css
// import { Grid } from 'tui-grid'
// import Grid from 'tui-grid'
// import Grid from '@toast-ui/vue-grid'
// import { Grid } from '@toast-ui/vue-grid'
import 'tui-grid/dist/tui-grid.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'

Vue.use(MyPlugin)
Vue.use(VueCookies)
Vue.use(Vuebar)
// Vue.use(Grid)

const moment = require('moment')
require('moment/locale/ko')
Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

global.jquery = require('jquery')
const $ = global.jquery
window.$ = $

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})

Vue.filter('comma', function (value) {
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
})

Vue.prototype.$filters = Vue.options.filters

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
