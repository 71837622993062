import Vue from 'vue'

const state = {
  baseUri: '/botw/spotInfo/checkFacility/'
}
const getters = {
}
const actions = {

  search: function (_context, [data, callback]) { // 점검대상 설비 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: data

    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [codeGroupId, codeId, data, callback]) { // 세부설비 상세 조회
    console.log('codeId : ', codeId)
    const axiosOption = {
      method: 'GET',
      // url: _context.state.baseUri + 'detail/' + codeGroupId + '/' + codeId
      url: _context.state.baseUri + 'detail/' + codeId,
      params: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  facilityDtlDown: function (_context, [spotSeq, codeGroupId, codeId, callback]) { // 점검대상 설비 PDF 내용 조회
    console.log('spotSeq, codeGroupId, codeId : ', spotSeq, codeGroupId, codeId)
    // pdf 전체 다운 구분
    let data = {}
    if (codeId !== null && codeId !== undefined) {
      data = {
        codeId: codeId,
        codeGroupId: codeGroupId
      }
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'facilityDtlDown/' + spotSeq,
      params: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
