import Vue from 'vue'

const state = {
  baseUri: '/botw/spotInfo/contract/'
}
const getters = {
}
const actions = {
  search: function (_context, [spotSeq, callback]) { // 계약정보 조회
    const axiosOption = {
      method: 'GET',
      params: {
        spotSeq: spotSeq
      },
      url: _context.state.baseUri + 'search'
    }
    console.log('actions search data : ', axiosOption.params)
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [spotSeq, contractSeq, callback]) { // 계약정보 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + spotSeq + '/' + contractSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  preview: function (_context, [spotSeq, callback]) { // 현황표 미리보기
    console.log('action preview : ', spotSeq)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'preview/' + spotSeq,
      params: {
        spotSeq: spotSeq
      }
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
