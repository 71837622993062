import Vue from 'vue'

const state = {
  // 설정 > 현장 설비관리
  baseUri: '/botw/config/spotFacility/'
}
const getters = {
}
const actions = {
  search: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData
    }
    console.log('ConfigSpotFacility-search', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  spotSearch: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      pageNum: paramData.pageNum,
      rowCount: paramData.rowCount,
      spotSeq: paramData.spotSeq,
      spotName: paramData.spotName,
      address: paramData.address
    }
    console.log('ConfigSpotFacility-spotSearch')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'spotSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      codeGroupId: paramData.codeGroupId,
      codeId: paramData.codeId
    }
    console.log('ConfigSpotFacility-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + `detail/${reqData.spotSeq}/${reqData.codeGroupId}/${reqData.codeId}`
    }
    Vue.axios(axiosOption).then(callback)
  },
  // [check] 현장 설비 수정
  update: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      childFacilityCodeId: paramData.childFacilityCodeId,
      checkTypeCodeId: paramData.checkTypeCodeId,
      spotSeq: paramData.spotSeq,
      facilitySeq: paramData.facilitySeq,
      setupLocation: paramData.setupLocation,
      setupDate: paramData.setupDate,
      setupUserName: paramData.setupUserName,
      setupUserMobileNum: paramData.setupUserMobileNum,
      setupCompanyName: paramData.setupCompanyName,
      setupCompanyMobileNum: paramData.setupCompanyMobileNum,
      setupUserEmail: paramData.setupUserEmail,
      checkUserSeq: paramData.checkUserSeq
    }
    console.log('ConfigSpotFacility-update', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  fileCodeUpdate: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('ConfigSpotFacility-fileCodeUpdate', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'fileCodeUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  fileCodeInit: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('ConfigSpotFacility-fileCodeInit', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'fileCodeInit',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  userSearch: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      pageNum: paramData.pageNum,
      rowCount: paramData.rowCount
    }
    console.log('ConfigSpotFacility-userSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'userSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
