import Vue from 'vue'

const state = {
  baseUri: '/botw/err/process/'
}
const getters = {
}
const actions = {
  codes: function (_context, callback) { // 관련 코드 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [spotSeq, searchParam, tempPageNum, rowCount, callback]) { // 내역 조회
    const params = {}
    if (tempPageNum !== null && rowCount !== null) {
      params.pageNum = tempPageNum
      params.rowCount = rowCount
      params.spotSeq = spotSeq
    }
    for (const key in searchParam) {
      if (Object.hasOwnProperty.call(searchParam, key)) {
        params[key] = searchParam[key]
      }
    }
    console.log('actions search : ', params)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: params
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [historySeq, spotSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + historySeq,
      params: {
        spotSeq: spotSeq
      }
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    console.log('insert data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    console.log('update data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  setupInfo: function (_context, [spotSeq, codeId, callback]) { // 세부설비 업체, 담당자
    console.log('action setupInfo : ', spotSeq, codeId)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'setupInfo/' + spotSeq + '/' + codeId
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
