import Vue from 'vue'

const state = {
  // 설정 > 기타설정 > 메뉴관리
  baseUri: '/botw/config/menu/'
}
const getters = {
}
const actions = {
  search: function (_context, [callback]) {
    console.log('ConfigMenu-search')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [menuSeq, callback]) {
    console.log('ConfigMenu-detail', menuSeq)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + menuSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      menuSeq: paramData.menuSeq,
      menuName: paramData.menuName,
      dataFlag: paramData.dataFlag
    }
    console.log('ConfigMenu-update', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
