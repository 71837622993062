import Vue from 'vue'

export default {
  // 로그인 지역명
  searchDongCode: function ({ state }, [data, callback]) {
    console.log('searchDongCode-data : ', data.upperDongCode, data.level)
    const reqData = {}
    if (data.upperDongCode === null || data.upperDongCode === undefined || data.upperDongCode === '') {
      reqData.level = Number(data.level)
    } else {
      reqData.dongCode = String(data.upperDongCode)
      reqData.level = Number(data.level) + 1
    }

    console.log('actions-searchDongCode', reqData)
    const axiosOption = {
      method: 'GET',
      url: '/botw/searchDong',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 로그인 현장명
  spotDongCode: function ({ state }, [data, callback]) {
    const reqData = {
      dongCode: String(data.dongCode)
      // spotSeq: Number(data.spotSeq)
    }
    console.log('spotDongCode', reqData)
    const axiosOption = {
      method: 'GET',
      url: `/botw/searchSpot/${reqData.dongCode}`
      // params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 로그인 소속명
  consignCompanyCode: function ({ state }, [data, callback]) { // 로그인
    const reqData = {
      spotSeq: Number(data.spotSeq)
    }
    console.log('consignCompanyCode', reqData)
    const axiosOption = {
      method: 'GET',
      // url: `/botw/config/consignComp/searchByLogin/${reqData.spotSeq}`
      url: '/botw/config/consignComp/searchByLogin'
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 로그인
  login: function (_context, [userData, callback]) { // 로그인
    const reqData = {
      // spotSeq: Number(userData.spotSeq),
      // consignCode: userData.consignCode,
      userId: userData.userId,
      userPass: userData.userPass
      // userPassword: 'b492ba4c5e1d27ab2ae3d650188532d0288c5e898680d86b00ad96b94014e6d330adfb619f7f20f4467925877d87e29c9ca187f00e481fd32a2d9ec2cf11bd78'
    }
    console.log('login', reqData)
    const axiosOption = {
      method: 'POST',
      // url: `/botw/login/${reqData.spotSeq}/${reqData.consignCode}`,
      url: '/botw/login',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 임시비밀번호 변경
  imsiPassChange: function ({ state }, [data, callback]) { // 로그인
    const reqData = {
      // userSeq: Number(data.userSeq),
      userId: data.userId,
      beforePassword: data.beforePassword,
      newPassword: data.newPassword
    }
    console.log('imsiPassChange', reqData)
    const axiosOption = {
      method: 'POST',
      url: '/botw/imsiPassChange',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  snackbarShow: function ({ state }, [msg, success]) { // snackbar display (action success, fail)
    // snackbar close
    state.snackbarFlag = false
    state.snackbarMsg = ''
    state.snackbarColor = ''
    state.snackbarTop = true
    state.snackbarBottom = false
    state.snackbarRight = true
    // state.snackbarVertical = true
    state.snackbarVertical = false
    state.snackbarAutoHeight = true

    setTimeout(function () {
      // snackbar show
      state.snackbarMsg = msg
      if (typeof success === 'string') {
        state.snackbarColor = success
      } else {
        if (success) {
          state.snackbarColor = 'success'
        } else {
          state.snackbarColor = 'error'
        }
      }
      state.snackbarFlag = true
    }, 100)
  },
  snackbarClose: function ({ state }) { // snackbar close
    state.snackbarFlag = false
    state.snackbarMsg = ''
    state.snackbarColor = ''
  },
  imageUpload: function (_context, [file, fileCode, fileOnlyOne, callback]) { // 이미지 업로드
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 10000
      }
      const data = new FormData()
      if (fileOnlyOne !== null) {
        data.append('fileOnlyOne', fileOnlyOne)
      }
      if (fileCode !== null) {
        data.append('fileCode', fileCode)
      }
      data.append('uploadFile', file)
      // 아래 주소 체크
      Vue.axios.post('/botw/file/imageUpload', data, axiosOption).then(callback)
    }
  },
  fileUpload: function (_context, [file, fileCode, fileOnlyOne, callback]) { // 파일 업로드
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 10000
      }
      const data = new FormData()
      if (fileOnlyOne !== null) {
        data.append('fileOnlyOne', fileOnlyOne)
      }
      if (fileCode !== null) {
        data.append('fileCode', fileCode)
      }
      data.append('uploadFile', file)
      // 아래 주소 체크
      Vue.axios.post('/botw/file/fileUpload', data, axiosOption).then(callback)
    }
  },
  fileDelete: function (_context, [fileCode, fileIndex, callback]) { // 파일 삭제
    console.log('actions fileDelete : ', fileCode, fileIndex)
    // 아래 주소 체크
    const axiosOption = {
      method: 'DELETE',
      url: '/botw/file/delete',
      data: {
        fileCode: fileCode,
        // back 에서 fileSeq으로 받고있어서 수정
        fileSeq: fileIndex
      }
    }
    Vue.axios(axiosOption).then(callback)
  },
  logout: function (_context, callback) { // 로그아웃
    const axiosOption = {
      method: 'GET',
      url: '/botw/logout'
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 에러 발생 시 다이얼로그 띄우기
  showErrorDialog: function ({ state }, payload) {
    state.dialogErrorTitle = payload.title
    state.dialogErrorMsg = payload.message
    state.dialogErrorFlag = true
    if (payload.autoClose === undefined || payload.autoClose) {
      const timer = setTimeout(function () {
        if (state.dialogErrorFlag) {
          state.dialogErrorFlag = false
          state.dialogTimer = null
          clearTimeout(timer)
        }
      }, 5000)
      state.dialogTimer = timer
    }
  },
  // 에러 다이얼로그 닫기
  closeErrorDialog: function ({ state }) {
    state.dialogErrorFlag = false
    const timer = state.dialogTimer
    if (timer !== null) {
      state.dialogTimer = null
      clearTimeout(timer)
    }
  }

}
