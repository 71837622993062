<template>
  <v-container fluid fill-height id="container" style="background-color:#f5f7fa;" ref="loginView">
    <div id="topBar"></div>
    <v-layout align-center justify-center>
      <v-flex xs11 sm8 md6 lg3 xl3>
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <v-form @submit.prevent="passes(loginSubmit)" class="login-from login-box form">
            <v-row id="titleArea" no-gutters justify="center">
              <!-- <div id="firstTitle">{{'정보통신설비 유지관리'}}</div>
              <div id="secondTitle">{{'DURU'}}</div>
              <div id="thirdTitle">{{'두루'}}</div> -->
              <v-img :src="images.fullLogo" id="logoTitle"></v-img>
              <div id="firstDivider"></div>
            </v-row>
            <v-card-text id="formArea">
              <v-col class="pa-0">
                <v-col class="pa-0 fieldArea">
                  <v-row id="idArea" tag="div" no-gutters justify="start" align="center">
                    <ValidationProvider name="user.userId" rules="required" v-slot="{ errors }">
                      <g-text-field v-model="userId" class="pa-0 ma-0" :placeholder="$t('user.userId')" type="text" id="userId" name="userId" clearable autocomplete="off" tabindex="1" :error-messages="errors" ref="userId" />
                    </ValidationProvider>
                  </v-row>
                </v-col>
                <v-col class="pa-0 fieldArea">
                  <v-row id="passArea" tag="div" no-gutters justify="start" align="center">
                    <ValidationProvider name="user.userPass" rules="required" v-slot="{ errors }">
                      <g-text-field v-model="userPassword" class="pa-0 ma-0" :placeholder="$t('user.userPass')" :type="userPassShow ? 'text' : 'password'" id="userPass" name="userPass" tabindex="2" clearable :append-icon="userPassShow ? 'visibility' : 'visibility_off'" @click:append="userPassShow = !userPassShow" autocomplete="new-password" ref="userPass" :error-messages="errors" />
                    </ValidationProvider>
                  </v-row>
                </v-col>
              </v-col>
              <!-- <div class="seper"></div> -->
              <v-btn depressed block id="buttonArea" type="submit">
                <v-img max-height="40px" max-width="330px" :src="images.loginBtn">
                  <v-row class="pa-0 ma-0" no-gutters align="center" justify="center">
                    <span id="btnTitle">{{'LOG IN'}}</span>
                    <v-img max-height="24px" max-width="24px" id="btnImg" :src="images.arrowIcons"></v-img>
                  </v-row>
                </v-img>
              </v-btn>
            </v-card-text>
          </v-form>
        </ValidationObserver>
      </v-flex>
    </v-layout>
    <div id="downBar">
      <div id="downTitle">{{'COPYRIGHT© 2024 GYEYOUNG I&C Co. All right reserved.'}}</div>
    </div>
  </v-container>
</template>

<script>
import { find } from 'lodash'
import GTextField from '@/components/form/GTextField'

export default {
  name: 'loginForm',
  components: {
    GTextField
  },
  data () {
    return {
      images: {
        fullLogo: require('@/assets/images/logo/logo_duru_full.svg'),
        loginBtn: require('@/assets/images/login/rectangle.png'),
        idIcons: require('@/assets/images/login/icon-id.png'),
        arrowIcons: require('@/assets/images/login/icon-arrow-r.png')
      },
      spotSeq: null,
      userId: '',
      userPassword: '',
      userPassShow: false
    }
  },
  watch: {
    //
  },
  mounted () {
    sessionStorage.removeItem('botw_access_info')

    this.$store.state.login.userSeq = null
    this.$store.state.login.userName = ''
    this.$store.state.login.userId = ''
    this.$store.state.login.spotSeq = null
    this.$store.state.login.spotName = null
    this.$store.state.login.loginKey = ''
    this.$store.state.login.encKey = ''
    this.$store.state.login.menuList = []
    this.$store.state.login.consignCompName = ''
    this.$store.state.login.userGroupCode = ''
    this.$store.state.login.userSpotList = []
  },
  created () {
    // window.removeEventListener('unload', this.removeCookies)
    // window.addEventListener('unload', this.removeCookies)
  },
  methods: {
    loginSubmit: function () {
      console.log('loginSubmit : ', this.userId, this.userPassword)
      const CryptoJS = require('crypto-js')
      const Base64 = require('base-64')
      // 한글로 입력 시 콘솔에러 발생 조치_24.08.26_(unescape(encodeURIComponent(this.userId)))
      const encId = Base64.encode(unescape(encodeURIComponent(this.userId)))
      const cryptOptions = {}
      const iv = window.getHex(this.$store.state.basicEncIv)
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      const key = window.getHex(this.$store.state.basicEncKey)
      // const encPw = CryptoJS.SHA512(this.userPassword).toString()
      const encPass = CryptoJS.AES.encrypt(this.userPassword, CryptoJS.enc.Hex.parse(key), cryptOptions).toString()

      // const encPw = CryptoJS.SHA512(this.userPassword).toString()
      const data = {
        userId: encId,
        userPass: encPass
      }
      if (data.userId !== '' && data.userPass !== '') {
        this.$store.dispatch('login', [data, this.loginSubmitCallback])
      }
    },
    loginSubmitCallback: function (response) {
      console.log('loginSubmitCallback :', response)
      if (response !== '' && response !== undefined && response.data !== '' && response.data !== undefined) {
        const data = response === undefined ? '' : response.data
        if (data !== '' && data !== undefined) {
          console.log('loginSubmitCallback : ', response.data)
          const success = response.data.success
          if (success) {
            const userSeq = response.data.userSeq
            const userName = response.data.userName
            const userId = this.userId
            const pwdChangeFlag = response.data.pwdChangeFlag
            const loginKey = response.data.loginKey
            const encKey = response.data.encKey
            const menuList = response.data.menuList
            const groupPermissionList = response.data.groupPermissionList
            const userGroupCode = response.data.userGroupCode
            const userSpotList = response.data.userSpotList
            const userGroupName = response.data.userGroupName

            const menu1List = []
            let groupPermission1List = []
            // dataFlag 반영 추가(with. 메뉴관리)_24.08.07
            for (let i = 0; i < menuList.length; i++) {
              const menu = menuList[i]
              const menuLevel = menu.level
              if (menuLevel === 1 && menu.menuSeq !== 1000000) { // 메인 메뉴목록으로 표출 안되게
                const groupPermission = find(groupPermissionList, { menuSeq: menu.menuSeq }).groupPermission
                menu.groupPermission = groupPermission
                // console.log('loginmenu', groupPermission, menu.menuSeq)
                menu.active = false
                groupPermission1List.push(menu)
                // console.log('menuList-menu', menu.dataFlag !== null && menu.dataFlag !== undefined && menu.dataFlag, menu)
                if (menu.dataFlag !== null && menu.dataFlag !== undefined && menu.dataFlag) menu1List.push(menu)
                if (menu.dataFlag === undefined || menu.dataFlag === null) menu1List.push(menu)
              }
            }
            console.log('menu1List 111 : ', menu1List)
            for (let i = 0; i < menu1List.length; i++) {
              const menu = menu1List[i]
              const menuSeq1 = menu.menuSeq
              const menu2List = []
              for (let j = 0; j < menuList.length; j++) {
                const menu2 = menuList[j]
                if (menu2.level === 2) {
                  menu2.active = false
                  const menuUpper2 = menu2.upperMenuSeq
                  if (menuSeq1 === menuUpper2) {
                    const groupPermission = find(groupPermissionList, { menuSeq: menu2.menuSeq }).groupPermission
                    menu2.groupPermission = groupPermission
                    // console.log('loginmenu2', groupPermission, menu2.menuSeq)
                    groupPermission1List.push(menu2)
                    // console.log('menuList-menu2', menu2.dataFlag !== null && menu2.dataFlag !== undefined && menu2.dataFlag, menu2)
                    if (menu2.dataFlag !== null && menu2.dataFlag !== undefined && menu2.dataFlag) menu2List.push(menu2)
                    if (menu2.dataFlag === undefined || menu2.dataFlag === null) menu2List.push(menu2)
                  } else {
                    if (menu2List.length > 0) {
                      menu.items = menu2List
                      break
                    }
                  }
                }
              }
              menu.items = menu2List
            }
            for (let i = 0; i < menu1List.length; i++) {
              const menu = menu1List[i]
              const items = menu.items
              for (let j = 0; j < items.length; j++) {
                const menu2 = items[j]
                const menuSeq2 = menu2.menuSeq
                const menu3List = []
                for (let k = 0; k < menuList.length; k++) {
                  const menu3 = menuList[k]
                  menu3.active = false
                  if (menu3.level === 3 && menu3.menuSeq !== 6040600) { // 운영자설정 > 시스템설정 > 시스템설정 메뉴 표출 X
                    const groupPermission = find(groupPermissionList, { menuSeq: menu3.menuSeq }).groupPermission
                    menu3.groupPermission = groupPermission
                    const menuUpper2 = menu3.upperMenuSeq
                    // console.log('loginmenu3', groupPermission, menu3.menuSeq)
                    if (menuSeq2 === menuUpper2) {
                      groupPermission1List.push(menu3)
                      // console.log('menuList-menu3', menu3.dataFlag !== null && menu3.dataFlag !== undefined && menu3.dataFlag, menu3)
                      if (menu3.dataFlag !== null && menu3.dataFlag !== undefined && menu3.dataFlag) menu3List.push(menu3)
                      if (menu3.dataFlag === undefined || menu3.dataFlag === null) menu3List.push(menu3)
                    } else {
                      // 점검실시 및 점검이력 3레벨에 통신설비만 들어와서 주석처리
                      // if (menu3List.length > 0) {
                      //   menu2.items = menu3List
                      //   break
                      // }
                    }
                  }
                }
                if (menu3List.length > 0) {
                  menu2.items = menu3List
                }
              }
            }

            console.log('menuList : ', menuList)
            console.log('menu1List : ', menu1List)
            groupPermission1List = JSON.parse(JSON.stringify(groupPermission1List))
            groupPermission1List.forEach((el, index) => {
              if (el.items !== undefined && el.items.length > 0) delete el.items
            })
            console.log('groupPermission1List : ', groupPermission1List)

            this.$store.state.login.userSeq = userSeq
            this.$store.state.login.userName = userName
            this.$store.state.login.userId = userId
            this.$store.state.login.pwdChangeFlag = pwdChangeFlag
            this.$store.state.login.loginKey = loginKey
            this.$store.state.login.encKey = encKey
            this.$store.state.login.menuList = JSON.parse(JSON.stringify(menu1List))
            this.$store.state.login.userGroupCode = userGroupCode
            this.$store.state.login.userSpotList = userSpotList
            this.$store.state.login.userGroupName = userGroupName

            // 로그인 직후는 첫번째 현정선택 (임시)
            if (userSpotList.length > 0) {
              this.$store.state.login.spotSeq = userSpotList[0].spotSeq
              this.$store.state.login.spotName = userSpotList[0].spotName
            }

            sessionStorage.setItem('botw_access_info', JSON.stringify({
              userSeq: userSeq,
              userName: userName,
              userId: userId,
              pwdChangeFlag: pwdChangeFlag,
              loginKey: loginKey,
              encKey: encKey,
              menuList: menu1List,
              userGroupCode: userGroupCode,
              userSpotList: userSpotList,
              spotSeq: this.$store.state.login.spotSeq,
              spotName: this.$store.state.login.spotName,
              userGroupName: userGroupName,
              groupPermissionList: groupPermission1List
            }))

            const mainPath = '/botw/main'
            this.$router.push({ path: mainPath })
          } else {
            console.log('login success fail...')
            // success === false
            const msg = response.data.failMsg
            setTimeout(() => {
              this.$store.dispatch('snackbarShow', [msg, success])
            }, 150)
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.container{padding: 0 0 0 0 !important;}
#container{padding: 0 0 0 0 !important; position: absolute;width: 100%;height: 100%;left: 0px;overflow: hidden;margin: 0px 0px 0px 0px;background: url('~@/assets/images/login/bg-login.png') no-repeat;background-position: 50% 46%;vertical-align: middle;overflow: hidden;}
#topBar{width: 100%;height: 8px;background-image: linear-gradient(to right, #19d5ad, #1c7ad8);top:0;position : absolute;z-index: 100;}
#downBar{width: 100%;height: 55px;background-image: linear-gradient(to right, #19d5ad, #1c7ad8);bottom:0;position : absolute;z-index: 100;}
#downTitle{width: 100%;line-height: normal;height: 14px;font-family: roboto;font-size: 12px;text-align: center;color: #d1edf3;top: 21px;position: relative;}

/* .login-box{position: absolute;display: block;width: 440px;height: 700px;border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;top: 36%;left: 50%;margin-top: -244.5px;margin-left: -192px;} */
.login-box{display: block;width: 440px;height: 500px;border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;}
#titleArea{border-radius: 20px 20px 0px 0px;height: 238px;margin-bottom: 0px;}
/* #firstTitle{width: 100%;height: 24px;font-family: NotoSansKR;font-size: 16px;font-style: normal;font-stretch: normal;font-weight: 900;letter-spacing: -0.5px;color: #1c7ad8;text-align: center;padding-top: 56px;}
#secondTitle{display : block;height: 57px;font-family: robotom;font-size: 48px;font-weight: 600;text-align: center;color: #444444;}
#thirdTitle{top: 134px;width: 100%;text-align: center;height: 16px;font-family: roboto;font-size: 14px;color: #888888;} */

#logoTitle {
  /* background: url('~@/assets/images/logo/logo_duru_full.png'); */
  width: 176px;
  height: 72.6px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 17%;
  margin-bottom: 5%;
  background-size: cover;
  flex-grow: 0;
  object-fit: contain;
  transform: scale(1.5);
}
#firstDivider{width: 362px;height: 24px;background: url('~@/assets/images/login/divider-login-diagonal.png') no-repeat;margin-bottom: 24px;margin-left: 32px;margin-right: 32px;background-size: cover;}
#formArea{background: none;padding: 0; padding-top: 30px;width: 100%;height: 261px;}

.fieldArea{display: block;width: 362px;height: 42px;border:1px solid #cccccc;border-radius: 25px;margin: 0 auto;padding:0;margin-bottom: 16px;}
.selectFieldArea{display: block;width: 362px;height: 42px;margin: 0 auto;padding:0;margin-bottom: 16px;}
#spotSi:deep(.v-label),#spotGu:deep(.v-label),#spotDong:deep(.v-label),#spotName:deep(.v-label),#consignCompName:deep(.v-label){font-family : roboto;color: #c3c6c8;}
#spotSi:deep(.v-input__append-inner),#spotGu:deep(.v-input__append-inner),#spotDong:deep(.v-input__append-inner),#spotName:deep(.v-input__append-inner),#consignCompName:deep(.v-input__append-inner){margin: 5.5px 0px 3.5px 0px;}
#spotSi:deep(.v-input__slot),#spotGu:deep(.v-input__slot),#spotDong:deep(.v-input__slot),#spotName:deep(.v-input__slot),#consignCompName:deep(.v-input__slot){margin: 0; height: 38px}
/* #logoTitle{width: 100%; height: 100%;transform: scale(0.7); } */
#userId.v-text-field >>> input {padding: 10px 8px 10px 0; color: #444;}
#idArea{background: url('~@/assets/images/login/icon-id.png') no-repeat;background-size: 24px 24px;padding-left:50px;background-position: 16px 8px;margin-bottom: 1px;}
#passArea{background: url('~@/assets/images/login/icon-pw.png') no-repeat;background-size: 24px 24px;padding-left:50px;background-position: 16px 8px;}
#passArea SPAN, #idArea SPAN {display: inline-block;width: 290px;height: 30px;line-height: 30px;font-family: roboto;font-size: 16px;color: #444444;outline: none;}
#passArea:deep(.v-input__slot)::before, #idArea:deep(.v-input__slot)::before{border-style: none !important;}
#passArea:deep(.v-input__slot)::after, #idArea:deep(.v-input__slot)::after{border-style: none !important;}
#passArea:deep(.v-input__append-inner){margin-top: 8px; margin-bottom: auto;}
#userPass, #userId{display: inline-block;width: 270px;height: 30px;line-height: 30px;font-family: roboto;font-size: 16px;color: #444444;margin-left: 48px;outline: none;}
#userPass::placeholder, #userId::placeholder{font-family : roboto;color: #c3c6c8;}

.seper {display: block;width: 362px;height: 1px;background-color: #cccccc;margin: 0 auto;margin-top: 45px;}

#buttonArea{display: block;width: 362px;height: 42px;background-color: #ffffff;float: none;margin: 16px auto;padding: 0 0 0 0;outline: none;}
#buttonArea:deep(.v-ripple__container){opacity: 0 !important;}
#buttonArea:hover:before{display: none;}
/* #buttonStr{display: inline-block;height: 40px;line-height: 40px;margin: 0 auto;outline: none;} */
#btnTitle{display: inline-block;height: 40px;line-height: 40px;font-family: roboto;font-size: 18px;text-align: center;color: #ffffff;outline: none;}
#btnImg{margin: 0px 4px ; outline: none;}

.login-from .login-logo {margin:40px auto 24px;}
.login-from .sub-title {width:29px;height:2px;margin:0 auto 40px;border:1px solid black;}
.login-from >>> .theme--light.v-sheet--outlined {border:0;}
.login-from >>> .v-card__text {padding:0 65px;}
.login-from >>> .v-text-field--solo fieldset,
.login-from >>> .v-text-field--solo .v-input__control,
.login-from >>> .v-text-field--solo .v-input__slot,
.login-from >>> .v-text-field--outlined fieldset,
.login-from >>> .v-text-field--outlined .v-input__control,
.login-from >>> .v-text-field--outlined .v-input__slot {border-radius: 0px;}
.login-from >>> .v-text-field--outlined fieldset {border-color:#c8c8c8 !important;}
.login-from >>> .v-text-field--outlined.primary--text fieldset {border-color:#292929 !important;}
.login-from >>> .v-text-field--outlined.error--text fieldset {border-color:#ff5252 !important;}
.login-from >>> .v-text-field--outlined > .v-input__control > .v-input__slot {min-height: 30px;}
.login-from >>> .v-input input {color:#292929 !important;}
.login-from >>> .v-input input::placeholder {color:#cecece !important;}
.login-from >>> .v-input__slot {margin-top: 2.5px;margin-bottom: 2.5px;}
.login-from >>> .v-text-field__details {text-align: right;}
.login-from >>> .theme--light.v-messages {color: #8D6E63;font-size: 10px;font-style: italic;}
.login-from >>> .v-text-field--enclosed .v-input__append-inner {margin-top:9px;}
.login-from >>> .v-input {font-size: 14px;}
.login-from >>> .v-card__text .v-input {margin-bottom:8px;}

</style>
