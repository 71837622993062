import Vue from 'vue'

const state = {
  baseUri: '/botw/config/consignComp/'
}
const getters = {
}
const actions = {
  search: function (_context, [searchParam, tempPageNum, rowCount, callback]) { // 내역 조회
    const params = {}
    if (tempPageNum !== null && rowCount !== null) {
      params.pageNum = tempPageNum
      params.rowCount = rowCount
    }
    for (const key in searchParam) {
      if (Object.hasOwnProperty.call(searchParam, key)) {
        params[key] = searchParam[key]
      }
    }
    console.log('actions search : ', params)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: params
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [consignCompSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + consignCompSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    console.log('insert data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    console.log('update data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  remove: function (_context, [data, callback]) { // 삭제
    console.log('remove data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'delete',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
