import Vue from 'vue'

const state = {
  baseUri: '/botw/config/user/'
}
const getters = {
}
const actions = {
  search: function (_context, [searchParam, tempPageNum, rowCount, callback]) { // 내역 조회
    const params = {}
    if (tempPageNum !== null && rowCount !== null) {
      params.pageNum = tempPageNum
      params.rowCount = rowCount
    }
    for (const key in searchParam) {
      if (Object.hasOwnProperty.call(searchParam, key)) {
        params[key] = searchParam[key]
      }
    }
    console.log('actions search : ', params)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: params
    }
    Vue.axios(axiosOption).then(callback)
  },
  codes: function (_context, callback) { // 사용자그룹 목록 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [userSeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + userSeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  spotList: function (_context, [spotSearchParam, callback]) { // 현장 조회
    console.log('spotList data: ', spotSearchParam)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'spotSearch',
      params: spotSearchParam
    }
    Vue.axios(axiosOption).then(callback)
  },
  consignCompSearch: function (_context, [consignSearchParam, callback]) { // 위탁업체 조회
    const params = {}
    for (const key in consignSearchParam) {
      if (Object.hasOwnProperty.call(consignSearchParam, key)) {
        params[key] = consignSearchParam[key]
      }
    }
    console.log('actions search : ', consignSearchParam)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'consignCompSearch',
      params: params
    }
    Vue.axios(axiosOption).then(callback)
  },
  remove: function (_context, [data, callback]) { // 삭제
    console.log('remove data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'delete',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 등록
    console.log('insert data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) { // 수정
    console.log('update data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  dupCheck: function (_context, [userId, callback]) { // 아이디 중복체크
    console.log('dupCheck data: ', userId)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'dupCheck/' + userId
    }
    Vue.axios(axiosOption).then(callback)
  },
  passInit: function (_context, [data, callback]) { // 비밀번호 초기화
    console.log('passInit data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'passInit',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
