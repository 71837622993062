import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

// 현장정보
import spotInfoCheckFacility from './modules/spotInfo/checkFacility/actions.js'
import spotInfoContract from './modules/spotInfo/contract/actions.js'

// 유지보수점검
// 점검실시
import maintainCheck from './modules/maintain/check/actions.js'

// 점검이력
import maintainHistory from './modules/maintain/history/actions.js'

// 성능점검
import performCheck from './modules/perform/check/actions.js'
import performHistory from './modules/perform/history/actions.js'

// 설비고장
import errProcess from './modules/err/process/actions.js'
import errHistory from './modules/err/history/actions.js'

// 설정
import configCheck from './modules/config/check//actions.js'
import configCode from './modules/config/code/actions.js'
import configCodeGroup from './modules/config/codeGroup/actions.js'
import configConsignComp from './modules/config/consignComp/actions.js'
import configContract from './modules/config/contract/actions.js'
import configFacility from './modules/config/facility/actions.js'
import configMenu from './modules/config/menu/actions.js'
import configSpot from './modules/config/spot/actions.js'
import configSpotCheck from './modules/config/spotCheck/actions.js'
import configPerformCheck from './modules/config/performCheck/actions.js'
import configSpotFacility from './modules/config/spotFacility/actions.js'
import configSystemConf from './modules/config/systemConf/actions.js'
import configUser from './modules/config/user/actions.js'
import configUserGroup from './modules/config/userGroup/actions.js'
import configUserGroupPermission from './modules/config/userGroupPermission/actions.js'

Vue.use(Vuex)

const state = {
  login: {
    userSeq: null,
    userName: '',
    userId: '',
    userSpotList: [], // 사용자의 담당 현장 목록
    spotSeq: null, // 선택한 현장
    spotName: null, // 선택한 현장
    pwdChangeFlag: false, // 임시비밀번호 변경 여부
    loginKey: '', // 로그인키
    consignCompName: '',
    userGroupName: '', // 사용자그룹명
    encKey: '', // 암호화키
    menuList: [], // 로그인 메뉴 리스트,
    userGroupCode: '',
    currentMenuTitle: '', // 선택한 메뉴 명칭
    currentMenuNavigation: '', // 선택한 메뉴 네비게이션
    currentMenuUpdateFlag: false, // 선택한 메뉴 수정권한 여부
    websocketConnection: null
    // userWebSocket: 'ws://localhost:8888/nhsims/websocket/user'
  },
  basicEncKey: 'GY-BOTW-RESTFU-SYSTEM#-AF1234DE9', // 기본 암호화 키
  basicEncIv: 'GY-BOTW-RESTFU-S', // 기본 iv 키
  snackbarFlag: false, // 스낵바 플래그
  snackbarTop: true, // 스낵바 top 위치 여부
  snackbarBottom: false, // 스낵바 bottom 위치 여부
  snackbarRight: true, // 스낵바 right 위치 여부
  snackbarVertical: false, // 스낵바 vertical 여부
  snackbarAutoHeight: true, // 스낵바 auto height 여부
  snackbarColor: '', // 스낵바 배경컬러
  snackbarMsg: '', // 스낵바 메시지,
  axiosLoading: false,
  dialogErrorTitle: '',
  dialogErrorMsg: '',
  dialogErrorFlag: false,
  dialogTimer: null,
  menu2ViewFlag: false,
  pdfDownStatus: false,
  file: {
    fileTms: 0, // 파일 업로드 가능 총 용량
    fileMs: 0, // 파일 업로드 개별 가능 용량
    fileTc: 0 // 파일 업로드 최대 개수
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    'spotInfo/checkFacility': spotInfoCheckFacility,
    'spotInfo/contract': spotInfoContract,
    'maintain/check': maintainCheck,
    'maintain/history': maintainHistory,
    'perform/check': performCheck,
    'perform/history': performHistory,
    'err/process': errProcess,
    'err/history': errHistory,

    'config/check': configCheck,
    'config/code': configCode,
    'config/codeGroup': configCodeGroup,
    'config/consignComp': configConsignComp,
    'config/contract': configContract,
    'config/facility': configFacility,
    'config/menu': configMenu,
    'config/spot': configSpot,
    'config/spotCheck': configSpotCheck,
    'config/performCheck': configPerformCheck,
    'config/spotFacility': configSpotFacility,
    'config/systemConf': configSystemConf,
    'config/user': configUser,
    'config/userGroup': configUserGroup,
    'config/userGroupPermission': configUserGroupPermission

  }
})
