import Vue from 'vue'

const state = {
  // 설정 > 기타설정 > 사용자그룹관리
  baseUri: '/botw/config/userGroup/'
}
const getters = {
}
const actions = {
  search: function (_context, [paramData, callback]) {
    console.log('ConfigCodeGroup-search', paramData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: paramData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [userGroupCode, callback]) {
    console.log('userGroup-detail', userGroupCode)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + userGroupCode
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) {
    console.log('ConfigCodeGroup-insert', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [data, callback]) {
    console.log('ConfigCodeGroup-update', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
