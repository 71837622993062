import Vue from 'vue'

const state = {
  baseUri: '/botw/config/spotCheck/'
}
const getters = {
}
const actions = {
  search: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData
    }
    console.log('ConfigSpotFacility-search', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      codeGroupId: paramData.codeGroupId,
      codeId: paramData.codeId
    }
    console.log('ConfigSpotFacility-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + `detail/${reqData.spotSeq}/${reqData.codeGroupId}/${reqData.codeId}`
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('spotCheck-insert', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
