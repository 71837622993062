import Vue from 'vue'

const state = {
  baseUri: '/botw/err/history/'
}
const getters = {
}
const actions = {
  codes: function (_context, callback) { // 관련 코드 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [spotSeq, searchParam, pageNum, rowCount, callback]) { // 내역 조회
    const params = {}
    if (pageNum !== null && rowCount !== null) {
      params.pageNum = pageNum
      params.rowCount = rowCount
    }
    for (const key in searchParam) {
      if (Object.hasOwnProperty.call(searchParam, key)) {
        params[key] = searchParam[key]
      }
    }
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search/' + spotSeq,
      params: params
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [historySeq, callback]) { // 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + historySeq
    }
    Vue.axios(axiosOption).then(callback)
  },
  pdfInfo: function (_context, [historySeq, callback]) { // 파일 다운로드 정보 (임시)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'pdfInfo/' + historySeq
    }
    Vue.axios(axiosOption).then(callback)
  }

}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
