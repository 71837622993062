import Vue from 'vue'

const state = {
  // 설정 > 기타설정 > 코드그룹관리
  baseUri: '/botw/config/codeGroup/'
}
const getters = {
}
const actions = {
  search: function (_context, [callback]) {
    console.log('ConfigCodeGroup-search')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      codeGroupId: paramData
    }
    console.log('ConfigCodeGroup-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + `detail/${reqData.codeGroupId}`
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      codeGroupId: paramData.codeGroupId,
      codeGroupName: paramData.codeGroupName,
      dataFlag: paramData.dataFlag
    }
    console.log('ConfigCodeGroup-insert', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      codeGroupId: paramData.codeGroupId,
      codeGroupName: paramData.codeGroupName,
      dataFlag: paramData.dataFlag
    }
    console.log('ConfigCodeGroup-update', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
