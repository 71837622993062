import Vue from 'vue'

const state = {
  // 설정 > 기타설정 > 코드
  baseUri: '/botw/config/code/'
}
const getters = {
}
const actions = {
  // 코드관리 검색조건 코드그룹 조회 진입
  codes: function (_context, [callback]) {
    console.log('ConfigCode-codes')
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes'
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 첫 코드관리 조회
  search: function (_context, [callback]) {
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search'
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 코드관리 조회
  // search: function (_context, [paramData, callback]) {
  //   paramData = JSON.parse(JSON.stringify(paramData))
  //   const reqData = {
  //     codeGroupId: paramData.codeGroupId,
  //     codeName: paramData.codeName
  //   }
  //   console.log('ConfigCode-search', reqData)
  //   const axiosOption = {
  //     method: 'GET',
  //     url: _context.state.baseUri + 'search',
  //     params: reqData
  //   }
  //   Vue.axios(axiosOption).then(callback)
  // },
  // 신규 진입 전 코드 조회
  searchBeforeInsert: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      codeGroupId: paramData.codeGroupId
    }
    console.log('ConfigCode-searchBeforeInsert', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'searchBeforeInsert',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 코드관리 상세
  detail: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      codeGroupId: paramData.codeGroupId,
      codeId: paramData.codeId
    }
    console.log('ConfigCode-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + `detail/${reqData.codeGroupId}/${reqData.codeId}`
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 코드 검색
  codeSearch: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      codeGroupId: paramData.codeGroupId,
      codeGroupName: paramData.codeGroupName
    }
    console.log('ConfigCode-codeSearch', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codeSearch',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 코드 등록
  insert: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      upperCodeGroupId: paramData.upperCodeGroupId,
      upperCodeId: paramData.upperCodeId,
      codeGroupId: paramData.codeGroupId,
      codeName: paramData.codeName,
      dataFlag: paramData.dataFlag
    }
    console.log('ConfigCode-insert', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 코드 등록
  update: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      codeGroupId: paramData.codeGroupId,
      codeGroupName: paramData.codeGroupName,
      codeId: paramData.codeId,
      codeName: paramData.codeName,
      dataFlag: paramData.dataFlag
    }
    console.log('ConfigCode-update', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
