<template>
  <v-snackbar :top="top" min-height="68" min-width="400" max-width="720" v-bind="$attrs" v-on="$listeners" v-bind:value="value" class="g-snackbar" :class="infoFlag ? 'snackbar_info' : 'snackbar_error'">
    <v-layout align-center justify-space-between fill-height>
      <v-layout align-center justify-start fill-height>
        <v-icon class="msg_icon"></v-icon>
        <div class="msg">
          <slot name="text"></slot>
        </div>
      </v-layout>
      <v-spacer/>
      <v-layout align-center justify-end fill-height>
        <v-icon class="close_icon" @click="snackbarClose"></v-icon>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: 'GSnackbar',
  inheritAttrs: true,
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    top: {
      type: Boolean,
      required: false,
      default: true
    },
    infoFlag: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
  }),
  created () {
  },
  mounted () {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    snackbarClose: function () {
      this.$emit('input', 'close')
    }
  }
}
</script>

<style scoped>
.g-snackbar.snackbar_info >>> .v-snack__wrapper {background-color: #1d3246;}
.g-snackbar.snackbar_error >>> .v-snack__wrapper {background-color: #d25f42;}
.g-snackbar >>> .v-snack__wrapper .msg_icon {width: 36px;height: 36px;object-fit: cover;background-size: contain;}
.g-snackbar.snackbar_info >>> .v-snack__wrapper .msg_icon {background: url('~@/assets/dialog/icon-popup-caution_small.svg') no-repeat center center;}
.g-snackbar.snackbar_error >>> .v-snack__wrapper .msg_icon {background: url('~@/assets/dialog/icon-popup-minus_small.svg') no-repeat center center;}
.g-snackbar >>> .v-snack__wrapper .msg {opacity: 0.9;font-family: NotoSansCJKKR;font-size: 14px;font-weight: normal;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #fff;margin-left:16px;max-width: 596px;white-space:normal;}
.g-snackbar .close_icon {width: 24px;height: 24px;object-fit: cover;background: url('~@/assets/dialog/icon-24-nagative-close.svg') no-repeat center center;background-size: contain;margin-left:16px;}
</style>
