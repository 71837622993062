import Vue from 'vue'

const state = {
  baseUri: '/botw/maintain/check/'
}
const getters = {
}
const actions = {
  codes: function (_context, [paramData, callback]) { // 유지보수점검실시 - 점검화면 코드조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq
    }
    console.log('MaintainCheck-codes', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  validCheckCycle: function (_context, [paramData, callback]) { // 유지보수점검실시 - 회차조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      checkTypeCodeId: paramData.checkTypeCodeId
    }
    console.log('MaintainCheck-validCheckCycle', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'validCheckCycle',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [paramData, callback]) { // 유지보수점검실시 - 신규 점검 생성
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      checkTypeCode: paramData.checkTypeCode,
      checkCycle: paramData.checkCycle,
      historyDate: paramData.historyDate
    }
    console.log('MaintainCheck-insert', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [paramData, callback]) { // 유지보수점검실시 - 점검 대상 리스트 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq
    }
    console.log('MaintainCheck-search', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) { // 유지보수점검실시 - 점검이력 상세 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      bundleSeq: paramData.bundleSeq,
      historySeq: paramData.historySeq
    }
    console.log('MaintainCheck-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detailUpdate: function (_context, [paramData, callback]) { // 유지보수점검실시 - 점검 내용 삽입
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      bundleSeq: paramData.bundleSeq,
      historySeq: paramData.historySeq,
      checkStatusCodeId: paramData.checkStatusCodeId,
      checkResult: paramData.checkResult,
      checkHistorySeq: paramData.checkHistorySeq,
      childFacilityCodeGroupId: paramData.childFacilityCodeGroupId,
      childFacilityCodeId: paramData.childFacilityCodeId,
      checkTypeCode: paramData.checkTypeCode,
      checkCycle: paramData.checkCycle,
      confirmFlag: paramData.confirmFlag,
      checkHistoryDate: paramData.historyDate,
      checkResultList: paramData.checkResultList
    }
    console.log('MaintainCheck-detailUpdate', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'detailUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  delete: function (_context, [paramData, callback]) { // 성능점검 - 점검 리스트 삭제
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      // userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      bundleSeq: paramData.bundleSeq
      // checkTypeCode: paramData.checkTypeCode,
      // checkCycle: paramData.checkCycle,
      // historyDate: paramData.historyDate
    }
    console.log('MaintainCheck-delete', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'delete',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
