// import Vue from 'vue'

const state = {
  baseUri: '/botw/config/facility/'
}
const getters = {
}
const actions = {
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
