import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { i18n } from '../i18n'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})

// 전화번호 유효성 규칙
const phoneRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid phone number`
  },
  validate (value, args) {
    // const PHONEREG = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/
    const PHONEREG = /^((01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4})|([0-9]{4}-[0-9]{4})$/
    return PHONEREG.test(value)
  }
}
// 비밀번호 유효성 규칙
const passRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid password`
  },
  validate (value, args) {
    const PASSREG = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@@#$%^&+=]).*$/
    return PASSREG.test(value)
  }
}

// 비밀번호 중복 규칙 - 후 비밀번호(value)에 전 비밀번호(other) 포함 체크
const passDuplicateRule = {
  validate: (value, { other }) => {
    console.log('passwordDuplicateRule', value, other)
    if (value.indexOf(other) >= 0) {
      return false
    }
    return true
  },
  message: '',
  params: [{ name: 'other', isTarget: true }]
}
// 아이디 중복 체크
const idDuplicateRule = {
  validate: (value, { other }) => {
    if (other === 1) {
      return false
    }
    return true
  },
  message: '',
  params: [{ name: 'other', isTarget: true }]
}

// 아이디 중복 체크
const extraDuplicateRule = {
  validate: (value, { other }) => {
    if (other === 1) {
      return false
    }
    return true
  },
  message: '',
  params: [{ name: 'other', isTarget: true }]
}

// 파일업로드 유효성 체크
const fileUploadRule = {
  validate: (value, { other }) => {
    if (other !== '') {
      return other
    }
    return true
  },
  params: [{ name: 'other', isTarget: true }],
  message: 'file upload error'
}

// 도메인 유효성 규칙
const urlRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid url`
  },
  validate (value, args) {
    const URLREG = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i')
    return URLREG.test(value)
  }
}

// 아이피 유효성 규칙
const ipRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid ip`
  },
  validate (value, args) {
    const IPREG = /^(1|2)?\d?\d([.](1|2)?\d?\d){3}$/
    return IPREG.test(value)
  }
}

// 비밀번호 유효성 규칙
const integerPassRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid password`
  },
  validate (value, args) {
    const IPASSREG = /([0-9])\1{2,}/g
    return !IPASSREG.test(value)
  }
}

// 날짜 형식 유효성 규칙
const dayRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid YYYY-MM-DD`
  },
  validate (value, args) {
    // const IPREG = /^(1|2)?\d?\d([.](1|2)?\d?\d){3}$/
    // return IPREG.test(value)
    // const DAYREG = /^1[0-9]{3}|2[0-9]{3}-?[0-9]{2}-?[0-9]{2}$/
    const DAYREG = /^(19[5-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
    return DAYREG.test(value)
  }
}

extend('phone', phoneRule)
extend('pass', passRule)
extend('pwDuplicate', passDuplicateRule)
extend('idDuplicate', idDuplicateRule)
extend('extraDuplicate', extraDuplicateRule)
extend('fileUpload', fileUploadRule)
extend('url', urlRule)
extend('ip', ipRule)
extend('date', dayRule)
extend('integerPass', integerPassRule)

for (const rule in rules) {
  // add the rule
  extend(rule, rules[rule])
  /*
  extend('password', {
    validate: (value, { other }) => value === other,
    message: 'The password confirmation does not match.',
    params: [{ name: 'other', isTarget: true }]
  })
  extend('date_format', {
    validate: (value) => new Promise(resolve => {
      let regex = new RegExp(/^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/)
      resolve({
        valid: value && regex.test(value)
      })
    }),
    message: 'date error'
  })
  */
}

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
