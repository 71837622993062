import Vue from 'vue'

const state = {
  baseUri: '/botw/perform/check/'
}
const getters = {
}
const actions = {
  codes: function (_context, [paramData, callback]) { // 성능점검 - 초기 생성조건 호출
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq
    }
    console.log('PerformCheck-codes', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  validCheckCycle: function (_context, [paramData, callback]) { // 성능점검 - 초기 생성조건 호출
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      checkTypeCodeId: paramData.checkTypeCodeId
    }
    console.log('PerformCheck-validCheckCycle', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'validCheckCycle',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [paramData, callback]) { // 성능점검 - 점검 내용 삽입
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      checkTypeCode: paramData.checkTypeCode,
      checkCycle: paramData.checkCycle,
      historyDate: paramData.historyDate
    }
    console.log('PerformCheck-insert', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [paramData, callback]) { //
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq
    }
    console.log('PerformCheck-search', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) { // 성능점검 - 세부설비 조건 별 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      bundleSeq: paramData.bundleSeq,
      historySeq: paramData.historySeq
    }
    console.log('PerformCheck-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detailUpdate: function (_context, [paramData, callback]) { // 성능점검 - 점검 내용 삽입
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('PerformCheck-detailUpdate', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'detailUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  delete: function (_context, [paramData, callback]) { // 성능점검 - 점검 리스트 삭제
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      // userSeq: paramData.userSeq,
      spotSeq: paramData.spotSeq,
      bundleSeq: paramData.bundleSeq
      // checkTypeCode: paramData.checkTypeCode,
      // checkCycle: paramData.checkCycle,
      // historyDate: paramData.historyDate
    }
    console.log('PerformCheck-delete', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'delete',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  fileCodeUpdate: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('PerformCheck-fileCodeUpdate', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'fileCodeUpdate',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  fileCodeInit: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('PerformCheck-fileCodeInit', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'fileCodeInit',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
