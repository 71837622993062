<template>
  <v-text-field
    class="g-text-field"
    :class="subClass"
    dense
    flat
    height="36"
    v-bind="$attrs"
    v-on="$listeners"
    v-bind:value="value"
  >
  </v-text-field>
</template>

<script>
export default {
  name: 'GTextField',
  inheritAttrs: true,
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    subClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
  }),
  created () {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>
.g-text-field.v-text-field:not(.v-text-field--outlined) {margin-top: 0;}
.g-text-field.v-text-field:not(.v-text-field--outlined) >>> .v-text-field__details {margin-bottom: 8px;}

.g-text-field.v-text-field--outlined, .g-text-field.v-text-field--solo {border-radius:3px;}
.g-text-field.v-text-field--outlined >>> .v-input__control > .v-input__slot {background-color: #fff;}
/*.g-text-field.v-text-field--outlined >>> .v-input__control > .v-input__slot:hover .v-label.v-label--active {color:#3ba8be !important;}
.g-text-field.v-input--is-focused.v-text-field--outlined >>> .v-input__control > .v-input__slot .v-label.v-label--active {color:#3ba8be !important;}*/
.g-text-field.v-text-field >>> fieldset {border-color:#cecece;}
.g-text-field.v-text-field--outlined >>> .v-input__slot:hover > fieldset, .g-text-field.v-text-field--solo >>> .v-input__slot:hover > fieldset {border: 1px solid #3ba8be;}
.g-text-field.v-text-field--outlined.error--text >>> .v-input__slot > fieldset, .g-text-field.v-text-field--solo.error--text >>> .v-input__slot > fieldset {border-color: #b71c1c;}
.g-text-field.v-text-field--outlined.error--text >>> .v-input__slot:hover > fieldset, .g-text-field.v-text-field--solo.error--text >>> .v-input__slot:hover > fieldset {border-color: #b71c1c !important;border-width:1.5px;}
.g-text-field.v-input--is-focused >>> fieldset {border: solid 1.5px #3ba8be !important;}
.g-text-field.v-input--is-focused.error--text >>> fieldset {border-color: #b71c1c !important;}
.g-text-field.v-text-field >>> input {padding: 11px 8px 9px 0; color: #444;}
.g-text-field.v-text-field >>> .v-text-field__slot {font-size:14px;}
.g-text-field.v-text-field.v-text-field--outlined >>> .v-text-field__slot .v-label:not(.v-label--active) {top: 8px;}
/*
.g-text-field.v-text-field.v-input--is-disabled >>> fieldset {border:1px solid #cdcdcd;}
.g-text-field.v-text-field.v-input--is-disabled >>> .v-input__control > .v-input__slot {background-color: #e8e8e8;}
.g-text-field.v-text-field.v-input--is-disabled >>> input {color:#444;}
*/

.g-text-field.v-text-field >>> .v-label {color:#888/*#cecece*/;}

.g-text-field.v-text-field--outlined.v-input--dense.v-text-field--single-line >>> .v-input__control > .v-input__slot, .g-text-field.v-text-field--outlined.v-input--dense.v-text-field--outlined >>> .v-input__control > .v-input__slot {min-height: 36px;}

.g-text-field.v-text-field:not(.error--text) >>> .v-input__slot:hover .v-text-field__slot .v-label {color: #3ba8be;}
.g-text-field.v-text-field.error--text >>> .v-input__slot .v-text-field__slot .v-label {color: #b71c1c!important;}
.g-text-field.v-text-field.error--text >>> .v-input__slot:hover .v-text-field__slot .v-label {color: #b71c1c!important;}
.g-text-field.v-text-field.v-text-field--outlined >>> .v-text-field__slot .v-label:not(.v-label--active) {top: 8px;}
.g-text-field.v-text-field.v-input--is-focused >>> .v-text-field__slot .v-label {font-weight: bold;}
.g-text-field.v-text-field.v-input--is-focused:not(.error--text) >>> .v-text-field__slot .v-label {color: #3ba8be!important;}
.g-text-field.v-text-field.v-input--is-focused.error--text >>> .v-text-field__slot .v-label {color: #b71c1c;}
.g-text-field.v-text-field.v-input--is-focused.error--text >>> .v-text-field__slot .v-label {color: #b71c1c!important;}

.g-text-field.v-text-field:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot::before {border-color: #cecece;/*bottom: 0px;*/}
.g-text-field.v-text-field:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot:hover::before {border-color: #3ba8be;}
.g-text-field.v-text-field.v-input--is-focused:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot::before,
.g-text-field.v-text-field.v-input--is-focused:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot::after,
.g-text-field.v-text-field.v-input--is-focused:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot:hover::before,
.g-text-field.v-text-field.v-input--is-focused:not(.v-text-field--outlined):not(.error--text) >>> .v-input__control .v-input__slot:hover::after {border-color: #3ba8be;/*bottom: 0px;*/}
.g-text-field.v-text-field.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot::before,
.g-text-field.v-text-field.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot::after {border-color: #b71c1c!important;border-top-width: 1px;}
.g-text-field.v-text-field.v-input--is-focused.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot::before,
.g-text-field.v-text-field.v-input--is-focused.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot::after,
.g-text-field.v-text-field.v-input--is-focused.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot:hover::before,
.g-text-field.v-text-field.v-input--is-focused.error--text:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot:hover::after {border-color: #b71c1c;border-top-width: 1px;/*bottom: 0px;*/}

/*.g-date.v-text-field.v-input--is-disabled {opacity: 0.3;}*/
.g-text-field.v-text-field.v-input--is-disabled >>> .v-input__control input {color: #cecece;}
.g-text-field.v-text-field.v-input--is-disabled:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot::before {border-image: none;border-color: #cecece;}
.g-text-field.v-text-field.v-input--is-disabled:not(.v-text-field--outlined) >>> .v-input__control .v-input__slot:hover::before {border-color: #cecece;border-image: none;}
.g-text-field.v-text-field.v-input--is-disabled.v-text-field--outlined:hover >>> .v-input__slot > fieldset {border-color: #cecece;}
.g-text-field.v-text-field.v-input--is-disabled >>> .v-text-field__slot .v-label {color: #cecece!important;}
.g-text-field.v-text-field.v-input--is-disabled >>> .v-input__append-inner {cursor: default;}
.g-text-field.v-text-field--outlined.v-input--is-disabled >>> .v-input__control > .v-input__slot:hover .v-label.v-label--active {color:#cecece !important;}

.g-text-field.v-text-field:not(.v-text-field--outlined) >>> .v-input__append-inner {margin: auto 0;}
.g-text-field.v-text-field >>> .v-input__append-inner .v-input__icon.v-input__icon--clear {display: none;}
.g-text-field.v-text-field.v-input--is-focused >>> .v-input__append-inner .v-input__icon.v-input__icon--clear {display: block;}
.g-text-field.v-text-field:not(.v-input--is-focused):not(.v-input--is-disabled) >>> .v-input__slot:hover .v-input__append-inner .v-input__icon.v-input__icon--clear {display: block;}
.g-text-field.v-text-field:not(.v-text-field--outlined) >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button,
.g-text-field.v-text-field:not(.v-text-field--outlined):hover >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button {color: rgba(0, 0, 0, 0.54);}

.g-text-field.v-text-field--outlined.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) >>> .v-input__append-inner {margin: auto 0;}
.g-text-field.v-text-field--outlined.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button,
.g-text-field.v-text-field--outlined.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo):hover >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button {color: rgba(0, 0, 0, 0.54);}

.g-text-field.v-text-field:not(.v-text-field--outlined):not(.error--text) >>> .v-text-field__details .v-messages__message,
.g-text-field.v-text-field--outlined:not(.error--text) >>> .v-text-field__details .v-messages__message {font-family: Pretendard;color: #cecece;}
.g-text-field.error--text >>> .v-text-field__details .v-messages__message {color: #b71c1c!important;}

.g-text-field.v-text-field--outlined.error--text:not(.v-input--is-focused) >>> .v-input__slot > fieldset {border-width: 1px;}
.g-text-field.v-text-field--outlined.v-input--is-focused >>> fieldset,
.g-text-field.v-text-field--outlined.v-input--is-focused >>> .v-input__slot:hover > fieldset {border-width: 1.5px!important;}

.theme--dark .g-text-field.insertForm >>> .v-text-field__slot input {color: #FFF;}
.theme--dark .g-text-field.insertForm.v-input--is-disabled >>> .v-text-field__slot input {color: #cecece!important;}
.theme--dark .g-text-field.insertForm.v-text-field--outlined >>> .v-input__slot {background-color: transparent;}
.theme--dark .g-text-field.insertForm.v-text-field:not(.v-text-field--outlined) >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button,
.theme--dark .g-text-field.insertForm.v-text-field:not(.v-text-field--outlined):hover >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button {color: rgba(255, 255, 255, 0.54);}
.theme--dark .g-text-field.insertForm.v-text-field--outlined.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button,
.theme--dark .g-text-field.insertForm.v-text-field--outlined.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo):hover >>> .v-input__append-inner .v-input__icon.v-input__icon--clear button {color: rgba(255, 255, 255, 0.54);}
.theme--dark .g-text-field.insertForm.v-text-field:not(.v-text-field--outlined):not(.error--text) >>> .v-text-field__details .v-messages__message,
.theme--dark .g-text-field.insertForm.v-text-field--outlined:not(.error--text) >>> .v-text-field__details .v-messages__message {color: #FFF;}
</style>
