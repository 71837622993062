import Vue from 'vue'

const state = {
  baseUri: '/botw/config/check/'
}
const getters = {
}
const actions = {
  search: function (_context, callback) { // 설비 목록 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [childFacilityCodeId, callback]) { // 설비 상세 조회
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail/' + childFacilityCodeId
    }
    Vue.axios(axiosOption).then(callback)
  },
  insert: function (_context, [data, callback]) { // 점검항목 등록 및 수정
    console.log('insert data: ', data)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'insert',
      data: data
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
