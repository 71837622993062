import Vue from 'vue'

const state = {
  baseUri: '/botw/maintain/history/'
}
const getters = {
}
const actions = {
  codes: function (_context, [paramData, callback]) { // 유지보수점검이력 - 점검이력 코드 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq
      // upfacilityCodeId: 'CD00001'
    }
    console.log('MaintainHistory-codes', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'codes',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  search: function (_context, [paramData, callback]) { // 유지보수점검이력 - 점검이력 리스트 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      spotSeq: paramData.spotSeq,
      checkYear: paramData.searchYear,
      checkCycle: paramData.checkCycle,
      checkTypeCodeId: paramData.checkTypeCodeId,
      upFacilityCodeGroupId: paramData.upFacilityCodeGroupId,
      upFacilityCodeId: paramData.upFacilityCodeId,
      facilityCodeGroupId: paramData.facilityCodeGroupId,
      facilityCodeId: paramData.facilityCodeId,
      childFacilityCodeGroupId: paramData.childFacilityCodeGroupId,
      childFacilityCodeId: paramData.childFacilityCodeId,
      checkStatusCodeId: paramData.checkStatusCodeId,
      rowCount: paramData.rowCount,
      pageNum: paramData.pageNum,
      confirmFlag: paramData.confirmFlag
    }
    console.log('MaintainHistory-search', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search',
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  pdfDown: function (_context, [paramData, callback]) { // 유지보수점검이력 - 점검이력 pdfDown
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('MaintainHistory-pdfDown', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'pdfDown',
      params: reqData
    }
    // console.log('MaintainHistory-pdfDown-axiosOption', axiosOption)
    Vue.axios(axiosOption).then(callback)
  },
  updateStatus: function (_context, [paramData, callback]) { // 유지보수점검이력 - 점검이력 보고상태 변경
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('MaintainHistory-updateStatus', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'updateStatus',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [paramData, callback]) { // 유지보수점검이력 - 세부설비 조건 별 조회
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = {
      bundleSeq: paramData.bundleSeq,
      spotSeq: paramData.spotSeq,
      historySeq: paramData.historySeq
    }
    const historySeq = paramData.historySeq
    console.log('MaintainHistory-detail', historySeq, reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'detail',
      // url: _context.state.baseUri + `detail/${historySeq}`,
      params: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
