import Vue from 'vue'
import Router from 'vue-router'
import store from '@/vuex/store'
import Login from './views/Login.vue'
// import store from '@/vuexTest/store'
// import Login from './views/LoginCopy.vue'

import _ from 'lodash'

Vue.use(Router)

const requireAuth = (title) => (to, from, next) => {
  const currentPath = to.path
  setTimeout(() => {
    const accessInfo = sessionStorage.getItem('botw_access_info')
    // console.log('accessInfo-Router2', to, from, next)
    if (accessInfo !== undefined && accessInfo !== null && accessInfo !== '') {
      const loginInfo = JSON.parse(accessInfo)
      store.state.login.userSeq = loginInfo.userSeq
      store.state.login.spotSeq = loginInfo.spotSeq
      store.state.login.spotName = loginInfo.spotName
      store.state.login.userId = loginInfo.userId
      store.state.login.userName = loginInfo.userName
      store.state.login.loginKey = loginInfo.loginKey
      store.state.login.encKey = loginInfo.encKey
      store.state.login.menuList = loginInfo.menuList
      store.state.login.consignCompName = loginInfo.consignCompName
      store.state.login.userSpotList = loginInfo.userSpotList
      store.state.login.userGroupCode = loginInfo.userGroupCode
      store.state.login.userGroupName = loginInfo.userGroupName
      store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag
      if ((from.path === '/loginForm' && currentPath === '/botw/main') || from.path === '/') {
        store.state.login.userSeq = loginInfo.userSeq
        store.state.login.spotSeq = loginInfo.spotSeq
        store.state.login.spotName = loginInfo.spotName
        store.state.login.userId = loginInfo.userId
        store.state.login.userName = loginInfo.userName
        store.state.login.loginKey = loginInfo.loginKey
        store.state.login.encKey = loginInfo.encKey
        store.state.login.menuList = loginInfo.menuList
        store.state.login.consignCompName = loginInfo.consignCompName
        store.state.login.userSpotList = loginInfo.userSpotList
        store.state.login.userGroupCode = loginInfo.userGroupCode
        store.state.login.userGroupName = loginInfo.userGroupName
        store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag

        let flag = false
        // console.log('currentPath : ', currentPath)
        _.map(loginInfo.menuList, function (menu1) {
          _.map(menu1.items, function (menu2) {
            if (menu2.items === undefined || menu2.items === null) { // depth가 2인 경우
              if (menu2.menuPath.indexOf(currentPath) >= 0) {
                // console.log('check1')
                flag = true
                // groupPermission의 경우 0 - 권한없음  // 1 -  읽기 //  2 - 읽기/쓰기
                const updateFlag = menu2.groupPermission === 2
                store.state.currentMenuUpdateFlag = updateFlag
                return null
              }
            } else {
              _.map(menu2.items, function (menu3) { // 메뉴 depth가 3인 경우
                if (menu3.menuPath.indexOf(currentPath) >= 0) {
                  // console.log('check2')
                  flag = true
                  const menuName = menu3.menuName
                  const updateFlag = menu3.groupPermission === 2
                  store.state.currentMenuTitle = menuName
                  store.state.currentMenuUpdateFlag = updateFlag
                  return null
                }
              })
            }
            if (flag) {
              return null
            }
          })
          if (flag) {
            return null
          }
        })
        return next()
      } else if (from.path !== '/') {
        if (loginInfo.pwdChangeFlag) {
          store.state.login.userSeq = loginInfo.userSeq
          store.state.login.spotSeq = loginInfo.spotSeq
          store.state.login.spotName = loginInfo.spotName
          store.state.login.userId = loginInfo.userId
          store.state.login.userName = loginInfo.userName
          store.state.login.loginKey = loginInfo.loginKey
          store.state.login.encKey = loginInfo.encKey
          store.state.login.menuList = loginInfo.menuList
          store.state.login.consignCompName = loginInfo.consignCompName
          store.state.login.userSpotList = loginInfo.userSpotList
          store.state.login.userGroupCode = loginInfo.userGroupCode
          store.state.login.userGroupName = loginInfo.userGroupName
          store.state.login.pwdChangeFlag = loginInfo.pwdChangeFlag

          let flag = false
          // console.log('currentPath : ', currentPath)
          _.map(loginInfo.menuList, function (menu1) {
            _.map(menu1.items, function (menu2) {
              if (menu2.items === undefined || menu2.items === null) { // depth가 2인 경우
                if (menu2.menuPath.indexOf(currentPath) >= 0) {
                  // console.log('check1')
                  flag = true
                  // groupPermission의 경우 0 - 권한없음  // 1 -  읽기 //  2 - 읽기/쓰기
                  const updateFlag = menu2.groupPermission === 2
                  store.state.currentMenuUpdateFlag = updateFlag
                  return null
                }
              } else {
                _.map(menu2.items, function (menu3) { // 메뉴 depth가 3인 경우
                  if (menu3.menuPath.indexOf(currentPath) >= 0) {
                    // console.log('check2')
                    flag = true
                    const menuName = menu3.menuName
                    const updateFlag = menu3.groupPermission === 2
                    store.state.currentMenuTitle = menuName
                    store.state.currentMenuUpdateFlag = updateFlag
                    return null
                  }
                })
              }
              if (flag) {
                return null
              }
            })
            if (flag) {
              return null
            }
          })
          return next()
        } else {
          store.state.login.userSeq = null
          store.state.login.spotSeq = null
          store.state.login.spotName = ''
          store.state.login.userId = ''
          store.state.login.userName = ''
          store.state.login.consignCompName = ''
          store.state.login.loginKey = ''
          store.state.login.encKey = ''
          store.state.login.menuList = []
          store.state.login.userGroupCode = ''
          store.state.login.userGroupName = ''
          store.state.login.userSpotList = []
          store.state.login.pwdChangeFlag = ''
          store.state.currentMenuUpdateFlag = false
          next('/loginForm')
        }
      }
    } else {
      store.state.login.userSeq = null
      store.state.login.spotSeq = null
      store.state.login.spotName = ''
      store.state.login.userId = ''
      store.state.login.userName = ''
      store.state.login.consignCompName = ''
      store.state.login.loginKey = ''
      store.state.login.encKey = ''
      store.state.login.menuList = []
      store.state.login.userGroupCode = ''
      store.state.login.userGroupName = ''
      store.state.login.userSpotList = []
      store.state.login.pwdChangeFlag = ''
      store.state.currentMenuUpdateFlag = false
      next('/loginForm')
    }
  }, 10)
}
// const ssoCheck = (title) => (to, from, next) => {
//   // SSO
//   window.bandiJS.agent.init({ isDebug: true })
//   window.bandiJS.agent.call({ requestType: 'alive' })

//   window.bandiJS.agent.call({
//     requestType: 'check_login',
//     isShownLoginForm: 'false',
//     withUserInfo: 'Y',
//     successCallback: function (response) {
//       if (response && response.success) {
//         const pSid = response.dataMap.p_sid
//         const disList = response.dataMap.userInfo.dis_list
//         if (disList.indexOf('TBS') === -1) {
//         // 로그인 검사하는 url로 리다이렉트
//         // alert('로그인검사')
//           window.location.href = '/ssoLoginCheck?p_sid=' + pSid
//         } else {
//           next('/loginForm')
//         }
//       } else {
//         next('/loginForm')
//       }
//     },
//     errorCallback: function (response) {
//       console.log('errorCallback : ' + response + ' / ' + response.error)
//       if (response && response.error === 'agent.is.off') {
//         next('/loginForm')
//       }
//     }
//   })
// }
// 로그인세션확인
function loginView () {
  const token = sessionStorage.getItem('botw_access_info')
  if (token !== undefined && token !== null && token !== '') {
    return () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
  } else {
    return Login
  }
}

export default new Router({
  // mode: 'history', // NH: hash모드 / 2.6버전: history모드
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    { path: '/loginForm', name: 'loginForm', component: loginView() },
    // { path: '/', name: 'ssoLoginForm', component: loginView(), beforeEnter: ssoCheck() },
    {
      path: '/botw/sub',
      name: 'Layout',
      component: () => import(/* webpackChunkName: "Layout" */ '@/views/sub/Layout.vue'),
      beforeEnter: requireAuth(''),
      children: [
        { path: '/botw/main', name: 'Main', component: () => import(/* webpackChunkName: "Main" */ '@/views/sub/main/Main.vue'), beforeEnter: requireAuth('Main') },

        // 현장정보
        { path: '/botw/spotInfo/checkFacility/', name: 'SpotInfoCheckFacilityList', component: () => import(/* webpackChunkName: "SpotInfoCheckFacilityList" */ '@/views/sub/spotInfo/checkFacility/List.vue'), beforeEnter: requireAuth('SpotInfoCheckFacilityList') },
        { path: '/botw/spotInfo/contract/', name: 'SpotInfoContractList', component: () => import(/* webpackChunkName: "SpotInfoContractList" */ '@/views/sub/spotInfo/contract/List.vue'), beforeEnter: requireAuth('SpotInfoContractList') },

        // 유지보수점검
        // // 점검실시
        { path: '/botw/maintain/check/', name: 'MaintainCheckList', component: () => import(/* webpackChunkName: "MaintainCheckList" */ '@/views/sub/maintain/check/List.vue'), beforeEnter: requireAuth('MaintainCheckList') },
        // // 점검이력
        { path: '/botw/maintain/history/', name: 'MaintainHistoryList', component: () => import(/* webpackChunkName: "MaintainHistoryList" */ '@/views/sub/maintain/history/List.vue'), beforeEnter: requireAuth('MaintainHistoryList') },

        // 성능점검
        // // 점검실시
        { path: '/botw/perform/check/', name: 'PerformCheckCommList', component: () => import(/* webpackChunkName: "PerformCheckCommList" */ '@/views/sub/perform/check/List.vue'), beforeEnter: requireAuth('PerformCheckList') },
        // // 점검이력
        { path: '/botw/perform/history/', name: 'PerformHistoryCommList', component: () => import(/* webpackChunkName: "PerformHistoryCommList" */ '@/views/sub/perform/history/List.vue'), beforeEnter: requireAuth('PerformHistoryList') },

        // 설비고장
        { path: '/botw/err/process/', name: 'ErrProcessList', component: () => import(/* webpackChunkName: "ErrProcessList" */ '@/views/sub/err/process/List.vue'), beforeEnter: requireAuth('ErrProcessList') },
        // { path: '/botw/err/history/', name: 'ErrHistoryList', component: () => import(/* webpackChunkName: "ErrHistoryList" */ '@/views/sub/err/history/List.vue'), beforeEnter: requireAuth('ErrHistoryList') },

        /* 설정 시작 */
        // 현장설비관리
        { path: '/botw/config/spotFacility/', name: 'ConfigSpotFacilityList', component: () => import(/* webpackChunkName: "ConfigSpotFacilityList" */ '@/views/sub/config/spotFacility/List.vue'), beforeEnter: requireAuth('ConfigSpotFacilityList') },

        // 현장별 점검항목관리
        { path: '/botw/config/spotCheck/', name: 'ConfigSpotCheckList', component: () => import(/* webpackChunkName: "ConfigSpotCheckList" */ '@/views/sub/config/spotCheck/List.vue'), beforeEnter: requireAuth('ConfigSpotCheckList') },
        { path: '/botw/config/facility/', name: 'ConfigFacilityList', component: () => import(/* webpackChunkName: "ConfigFacilityList" */ '@/views/sub/config/facility/List.vue'), beforeEnter: requireAuth('ConfigFacilityList') },
        // 사용자관리
        { path: '/botw/config/user/', name: 'ConfigUserList', component: () => import(/* webpackChunkName: "ConfigUserList" */ '@/views/sub/config/user/List.vue'), beforeEnter: requireAuth('ConfigUserList') },
        // 설정 - 관리자메뉴
        { path: '/botw/config/contract/', name: 'ConfigContractList', component: () => import(/* webpackChunkName: "ConfigContractList" */ '@/views/sub/config/contract/List.vue'), beforeEnter: requireAuth('ConfigContractList') },
        { path: '/botw/config/check/', name: 'ConfigCheckList', component: () => import(/* webpackChunkName: "ConfigCheckList" */ '@/views/sub/config/check/List.vue'), beforeEnter: requireAuth('ConfigCheckList') },
        { path: '/botw/config/performCheck/', name: 'ConfigSpotPerformCheckList', component: () => import(/* webpackChunkName: "ConfigSpotPerformCheckList" */ '@/views/sub/config/performCheck/List.vue'), beforeEnter: requireAuth('ConfigSpotPerformCheckList') },
        { path: '/botw/config/spot/', name: 'ConfigSpotList', component: () => import(/* webpackChunkName: "ConfigSpotList" */ '@/views/sub/config/spot/List.vue'), beforeEnter: requireAuth('ConfigSpotList') },
        { path: '/botw/config/consignComp/', name: 'ConfigConsignCompList', component: () => import(/* webpackChunkName: "ConfigConsignCompList" */ '@/views/sub/config/consignComp/List.vue'), beforeEnter: requireAuth('ConfigConsignCompList') },

        // 설정 - 기타설정
        { path: '/botw/config/codeGroup/', name: 'ConfigCodeGroupList', component: () => import(/* webpackChunkName: "ConfigCodeGroupList" */ '@/views/sub/config/codeGroup/List.vue'), beforeEnter: requireAuth('ConfigCodeGroupList') },
        { path: '/botw/config/code/', name: 'ConfigCodeList', component: () => import(/* webpackChunkName: "ConfigCodeList" */ '@/views/sub/config/code/List.vue'), beforeEnter: requireAuth('ConfigCodeList') },
        { path: '/botw/config/menu/', name: 'ConfigMenuList', component: () => import(/* webpackChunkName: "ConfigMenuList" */ '@/views/sub/config/menu/List.vue'), beforeEnter: requireAuth('ConfigMenuList') },
        { path: '/botw/config/userGroup/', name: 'ConfigUserGroupList', component: () => import(/* webpackChunkName: "ConfigUserGroupList" */ '@/views/sub/config/userGroup/List.vue'), beforeEnter: requireAuth('ConfigUserGroupList') },
        { path: '/botw/config/systemConf/', name: 'ConfigSystemConfList', component: () => import(/* webpackChunkName: "ConfigSystemConfList" */ '@/views/sub/config/systemConf/List.vue'), beforeEnter: requireAuth('ConfigSystemConfList') },
        { path: '/botw/config/userGroupPermission/', name: 'ConfigUserGroupPermissionList', component: () => import(/* webpackChunkName: "ConfigUserGroupPermissionList" */ '@/views/sub/config/userGroupPermission/List.vue'), beforeEnter: requireAuth('ConfigUserGroupPermissionList') },
        /* 설정 끝 */

        /* error : 403 */
        { path: '/botw/error/403', name: 'Error403', component: () => import(/* webpackChunkName: "Error403" */ '@/views/sub/403.vue') }
      ]
    },

    { path: '*', redirect: '/loginForm' }
  ]
})
