import Vue from 'vue'

const state = {
  // 설정 > 기타설정 > 사용자 그룹 권한 설정
  baseUri: '/botw/config/userGroupPermission/'
}
const getters = {
}
const actions = {
  search: function (_context, [callback]) {
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + 'search'
    }
    Vue.axios(axiosOption).then(callback)
  },
  detail: function (_context, [id, callback]) {
    const reqData = JSON.parse(JSON.stringify(id))
    console.log('ConfigCodeGroup-detail', reqData)
    const axiosOption = {
      method: 'GET',
      url: _context.state.baseUri + `detail/${reqData}`
    }
    Vue.axios(axiosOption).then(callback)
  },
  update: function (_context, [paramData, callback]) {
    paramData = JSON.parse(JSON.stringify(paramData))
    const reqData = paramData
    console.log('ConfigCodeGroup-update', reqData)
    const axiosOption = {
      method: 'POST',
      url: _context.state.baseUri + 'update',
      data: reqData
    }
    Vue.axios(axiosOption).then(callback)
  }
}
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
