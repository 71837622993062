import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

const MyPlugin = {
  install (Vue, options) {
    // 유효성 검증
    window.formValidate = {
      // 서버 응답 데이터 유효성 체크
      check: function (data, $refs, storeObj) {
        try {
          if (data.validateFlag === undefined || data.errorList === undefined) {
            return true
          }
          if (data.validateFlag != null) {
            if (data.errorList != null && data.errorList.length > 0) {
              let errorSnackFlag = false
              let errorSnackMsg = ''
              for (let i = 0; i < data.errorList.length; i++) {
                const fieldName = data.errorList[i].field
                const message = data.errorList[i].message
                if ($refs[fieldName] !== undefined) {
                  $refs[fieldName].setErrors([message])
                } else {
                  errorSnackFlag = true
                  if (errorSnackMsg !== '') {
                    errorSnackMsg += '<br>'
                  }
                  errorSnackMsg += message
                }
              }

              // console.log('errorSnackFlag', errorSnackFlag, 'errorSnackMsg', errorSnackMsg, window.selfObj.$store)
              // if (errorSnackFlag && window.selfObj.$store !== undefined && window.selfObj.$store !== null) {
              //   window.selfObj.$store.dispatch('snackbarShow', [errorSnackMsg, false])
              // } else if (errorSnackFlag && window.selfObj.$store === undefined && storeObj !== undefined && storeObj !== null) {
              //   storeObj.dispatch('snackbarShow', [errorSnackMsg, false])
              // }

              // 부장님이 수정해주심
              if (errorSnackFlag && window.selfObj.$store !== undefined && window.selfObj.$store !== null) {
                window.selfObj.$store.state.snackbarMsg = errorSnackMsg
                // window.selfObj.$store.dispatch('snackbarShow', [errorSnackMsg, false])
              } else if (errorSnackFlag && window.selfObj.$store === undefined && storeObj !== undefined && storeObj !== null) {
                storeObj.state.snackbarMsg = errorSnackMsg
                // storeObj.dispatch('snackbarShow', [errorSnackMsg, false])
              }
            }
            return false
          }
        } catch (e) {
          console.log(e)
        }
        return true
      }
    }
    // 헥사값 변경
    window.getHex = function (str) {
      let str16 = ''
      for (let i = 0; i < str.length; i++) {
        str16 += '' + str.charCodeAt(i).toString(16)
      }
      return str16
    }

    // 요청 데이터 암호화 하기
    window.getEncryptData = function (key, iv, info) {
      const CryptoJS = require('crypto-js')
      const cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      return CryptoJS.AES.encrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions).toString()
    }

    // 응답 데이터 복호화 하기
    window.getDecryptData = function (key, iv, info) {
      const CryptoJS = require('crypto-js')
      const cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      return CryptoJS.AES.decrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions).toString(CryptoJS.enc.Utf8)
    }

    Vue.mixin({
      mounted () { // component 호출 시 항상 호출 된 후, component mount 호출
        window.selfObj = this
      },
      methods: {
        callPdfDown: function (target, fileName) {
          console.log('myPlugin pdfDown function start / target ', target, ' / fileName ', fileName)
          // eslint-disable-next-line
          const pdf = new jsPDF({ unit: 'px', format: 'a4'})

          let errorFlag = false
          let targetIdList = []
          if (typeof target === 'string') {
            targetIdList.push(target)
          } else if (Array.isArray(target)) {
            targetIdList = target
          } else {
            errorFlag = true
          }

          this.$store.state.axiosLoading = true
          setTimeout(() => {
            if (!errorFlag) {
              // init data
              let totalPage = 0
              let pageIdx = 1
              const pageNumArr = []
              const pdfImageScale = 0.325
              const imgWidth = 200 * 11.4
              const imgHeight = 155 * 11.4
              const canvasWidth = imgWidth * pdfImageScale
              const canvasHeight = imgHeight * pdfImageScale

              // calculate total page & element page
              targetIdList.forEach(ele => {
                const targetObj = document.querySelector('#' + ele)
                const contentsHeight = targetObj.clientHeight * 1.29
                const pageNum = Math.ceil(contentsHeight / imgHeight)
                totalPage += pageNum
                pageNumArr.push(pageNum)
              })
              console.log(' totalPage : ', totalPage)
              let targetIdx = 0

              targetIdList.map((ele, index) => {
                const targetObj = document.querySelector('#' + ele)
                console.log('targetId element : ', ele, targetObj)
                // targetIdList 갯수가 많을 시 화면이 멈춘듯 보여서 추가
                setTimeout(() => {
                  html2canvas(targetObj, { scale: 1.29, useCORS: true, allowTaint: true, logging: false })
                    .then((canvas) => {
                      console.log('html2canvas start')
                      const currPageNum = pageNumArr[index]
                      for (let i = 1; i <= currPageNum; i++) {
                        const srcImg = canvas
                        const sY = imgHeight * (i - 1)
                        const sX = 0
                        const dX = 0
                        const dY = 0

                        let onePageCanvas = null
                        onePageCanvas = document.createElement('canvas')
                        onePageCanvas.width = imgWidth
                        onePageCanvas.height = imgHeight

                        const ctx = onePageCanvas.getContext('2d')
                        ctx.clearRect(0, 0, imgWidth, imgHeight)
                        ctx.fillStyle = 'white'
                        ctx.fillRect(0, 0, imgWidth, imgHeight)

                        const drawImageWidth = canvas.width
                        const drawImageHeight = canvas.height
                        ctx.drawImage(srcImg, sX, sY, drawImageWidth, drawImageHeight, dX, dY, drawImageWidth, drawImageHeight)
                        const canvasDataURL = onePageCanvas.toDataURL('image/jpeg', 1.0)

                        if ((i - 1) > 0) {
                          pageIdx = pageIdx + 1
                          pdf.addPage({ unit: 'px', format: 'a4' })
                        }

                        pdf.setPage(pageIdx)
                        pdf.addImage(canvasDataURL, 'jpeg', 15, 30, canvasWidth, canvasHeight, undefined, 'FAST')
                      }
                      targetIdx = targetIdx + 1
                      pageIdx = pageIdx + 1

                      if (targetIdList.length === targetIdx) {
                        console.log('save targetIdx : ', targetIdx, 'pageIdx : ', pageIdx)
                        pdf.save(fileName + '.pdf')
                        setTimeout(function () {
                          this.$store.state.axiosLoading = false
                          this.$store.state.pdfDownStatus = false
                        }.bind(this), 100)
                      } else {
                        pdf.addPage({ unit: 'px', format: 'a4' })
                      }
                    })
                }, 10)
                return console.log('Done!!')
                // pdf 미리보기 오픈
                // setTimeout(function () {
                //   window.open(pdf.output('bloburi'), '_blank')
                // }, 3000)
              })
            }
          }, 100)
        }
      }
    })
  }
}
export default MyPlugin
